
import image from '.././img/U-Park-Master-plan-board-40-30-1-150x150.png'



import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // تأكد من تضمين CSS الخاص بالمكتبة
import 'aos/dist/aos'

const Gallery = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const images = [
        'https://tasheedegypt.com/wp-content/uploads/2021/09/U-Park-Master-plan-board-40-30-1.png',
        'https://tasheedegypt.com/wp-content/uploads/2021/09/U-Park-Master-plan-board-40-30-2.png'
    ];
    
    return (
        <div className="text-center">
            <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            <h2 className="text-4xl mb-4 italic font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-blue-800">
                PROJECT PLAN
            </h2>
            </div>
            <div data-aos="zoom-in" data-aos-anchor-placement="top-bottom" className="flex justify-evenly mb-3">
                {images.map((image, index) => (
                <dl key={index} className="px-2">
                    <dt>
                    <a
                        href="#!"
                        onClick={(e) => {
                        e.preventDefault();
                        setPhotoIndex(index);
                        setIsOpen(true);
                        }}
                    >
                        <img
                        loading="lazy"
                        decoding="async"
                        width="150"
                        height="150"
                        src={image}
                        srcSet={`${image} 150w, ${image.replace('150x150', '650x650')} 650w, ${image.replace('150x150', '1300x1300')} 1300w`}
                        sizes="(max-width: 150px) 100vw, 150px"
                        />
                    </a>
                    </dt>
                </dl>
                ))}
        
                {isOpen && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                />
                )}
            </div>
        </div>
    );
};

export default Gallery;
