
import Card from './Card'

import 'aos/dist/aos.css';

// icons
import { FaLeaf } from "react-icons/fa6";
import { FaFan } from 'react-icons/fa';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FaMobileAlt } from 'react-icons/fa';
import { FaAward } from 'react-icons/fa';
export default function Cards  () {
    return (
        <div data-aos='zoom-in' data-aos-anchor-placement="top-bottom" className="flex flex-wrap justify-around py-5 gap-2">
            <Card 
                title="ECO CONSTRUCTION"  
                description="We use environment friendly building materials"
                icon={FaLeaf}
            />
            <Card 
                title="WINDOW IN BATHROOM"  
                description="Ventilated bathroom"
                icon={FaFan}
            />
            <Card 
                title="ATRACTIVE LOCATION"  
                description="At the Heart of smouha"
                icon={FaMapMarkerAlt}
            />
            <Card 
                title="SMART LIVING"  
                description="Enjoy home control using your mobile device"
                icon={FaMobileAlt}
            />
            <Card   
                title="AWARDED DESIGN"  
                description="Attractive building design"
                icon={FaAward}
            />
        </div>
    )
}



