import { useEffect, useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';

import { BsArrowUpSquareFill } from "react-icons/bs";

    function ScrollBar() {
    const [showScroll, setShowScroll] = useState(false);

    const checkScrollTop = () => {
        if (!showScroll && window.scrollY > 800) {
        setShowScroll(true);
        } else if (showScroll && window.scrollY <= 800) {
        setShowScroll(false);
        }
    };

    const scrollToTop = () => {
        scroll.scrollToTop({ duration: 800 });
    };

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);
        return () => {
        window.removeEventListener('scroll', checkScrollTop);
        };
    }, [showScroll]);

    return (
        <button
        onClick={scrollToTop}
        className={`fixed bottom-20 right-4 md:bottom-10 md:right-6 text-blue-500 border-none outline-none shadow-md text-3xl transition-opacity duration-300 ${
            showScroll ? 'opacity-100' : 'opacity-0'
        }`}
        >
        <BsArrowUpSquareFill/>
        </button>
    );
}

export default ScrollBar;
