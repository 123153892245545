import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import image_1 from '../img/upark-1.png'
import image_2 from '../img/upark-2.png'
import image_3 from '../img/upark-3.png'
import image_4 from '../img/upark-4.png'

// css
import '../css/Slider.css' ;
import 'aos/dist/aos.css';

const Upark = () => {
    const settings = {
      dots: false, // لإظهار النقاط
      infinite: true, // لتمرير الصور بشكل لانهائي
      speed: 4000, // سرعة التمرير (3 ثواني)
      slidesToShow: 1, // عرض شريحة واحدة في كل مرة
      slidesToScroll: 1, // التمرير شريحة واحدة في كل مرة
      autoplay: true, // لتفعيل التمرير التلقائي
      autoplaySpeed: 0, // سرعة التمرير التلقائي (0 يعني التمرير المستمر)
      cssEase: 'linear' // لجعل التمرير سلساً
    };
  
    const images= [
        image_1,
        image_2,
        image_3,
        image_4,

    ];
  
    return (
        <div className="slider-upark justify-center">
            {/* <Slider {...settings}>
            {images.map((image, index) => (
                <div key={index}>
                <img src={image} alt={`slide-${index}`} />
                </div>
            ))}
            </Slider> */}
            <div data-aos="zoom-in" data-aos-anchor-placement="top-bottom" className='flex'>
                <img className='w-36 lg:w-60 p-1' src={image_1} alt="" />
                <img className='w-36 lg:w-60 p-1' src={image_2} alt="" />
            </div>
            <div data-aos="zoom-in" data-aos-anchor-placement="top-bottom" className='flex'>
                <img className='w-36 lg:w-60 p-1' src={image_3} alt="" />
                <img className='w-36 lg:w-60 p-1' src={image_4} alt="" />
            </div>
        </div>
    );
};

export default Upark;