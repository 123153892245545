import React from 'react';

import 'aos/dist/aos.css';


const Card = ({ title, description, icon: Icon }) => {
    return (
      <div className="bg-gray-50 flex flex-col justify-start items-center gap-1 md-gap-2 w-36 md:w-64 text-center md:h-52 drop-shadow-2xl p-3 my-1 rounded-xl hover:-translate-y-2 transition-transform duration-500 ease-in-out cursor-pointer">
        <div className="my-3 rounded-full border-2 border-orange-700 p-3 text-3xl text-orange-700">
          <Icon />
        </div>
        <div className="text-orange-900 font-bold leading-tight text-xs md:text-sm">
          {title}
        </div>
        <div className="text-font_blue text-xs md:text-sm">
          {description}
        </div>
      </div>
    );
  }
  
  export default Card;