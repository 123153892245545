
import  { useEffect } from 'react';
import AOS from 'aos';

import TopNav from "./TopNav"
import Header from "./Header"

// pages 
import Home from "./pages/Home" ;
import OurWork from "./pages/OurWork" ;
import Footer from "./pages/Footer";

//components


import { Route , Routes } from "react-router-dom" ;
import ScrollBar from './components/ScrollBar';


export default function App() {
    useEffect(() => {
        AOS.init({
            duration: 3000, // مدة المؤثرات بالألفي ثانية
            offset: 200, // المسافة قبل تفعيل المؤثر
            once: true, 
        });
    }, []);
    return (
        <>
            
            <Header/>
            <TopNav/>
            
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="our-work" element={<OurWork/>}/>
            </Routes>
            
            <Footer/>
            <ScrollBar/>
        </>
    )
}