// icons
import { FaPhone } from "react-icons/fa6";
import { FaRegEnvelope } from "react-icons/fa6";
import { BsMap } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";

import Logo from '../components/Logo'
import { Link} from 'react-router-dom';

export default function Footer() {
    return (
        <>
        <div className="bg-customBlue px-2 lg:px-28 py-3 flex flex-col md:flex-row gap-3 justify-between">
            {/* WHAT WE DO */}
            <div className='md:w-1/3'>
                <Logo/>
                <p className='text-customGray text-sm lg:text-lg py-4'>Find out all the ways to enjoy <strong>Tasheed</strong> Real Estate Development</p>
            </div>
            <div>
                <div className='mb-2'>
                    <h6 className='text-white font-bold text-xs md:text-sm'>QUICK LINKS</h6>
                </div>
                <div className='flex flex-col text-customGray text-xs lg:text-sm'>
                <Link className="py-1" to="/">HOME</Link>
                <Link className="py-1" to="/our-work">WHAT WE DO</Link>
                <Link className="py-1" to="/property">PROPERTY</Link>
                <Link className="py-1" to="/contact">CONTACT US</Link>
                </div>
            </div>
            {/* CONTACT */}
            <div>
                <div className='mb-2'>
                    <h6 className='text-white font-bold text-sm '>KEEP IN TOUCH</h6>
                </div>
                <div className='flex flex-col items-start text-white text-xs md:text-sm'>
                    <div className="flex justify-center items-center ">
                        <small className="text-customGray"><FaPhone/></small>
                        <a href="tel:16673" className="text-customGray py-1">16673</a>
                    </div>
                    <div className="flex justify-center items-center ">
                        <small className="text-customGray pe-1 pt-1"><FaRegEnvelope/></small>
                        <span className="text-customGray py-1">tasheed@tasheedegypt.com</span>
                    </div>
                    <div className="flex justify-center items-center ">
                        <small className="text-customGray pe-1 pt-1"><BsMap/></small>
                        <a href="https://www.google.com/maps/@31.2080317,29.9660108,18z?entry=ttu" target="_blank" rel="noopener noreferrer" 
                        className="text-customGray py-1">alex-front of green plaza</a>
                    </div>
                    {/* social media */}
                    <div className="flex justify-around gap-2 w-full my-1">
                        <a href="#" className="border-gray-100 border-2 rounded-lg text-white p-1 mt-1">
                            <BsFacebook/>
                        </a>
                        <a href="#" className="border-gray-100 border-2 rounded-lg text-white p-1 mt-1">
                            <BsInstagram/>
                        </a>
                        <a href="#" className="border-gray-100 border-2 rounded-lg text-white p-1 mt-1">
                            <BsYoutube/>
                        </a>
                        <a href="#" className="border-gray-100 border-2 rounded-lg text-white p-1 mt-1">
                            <BsLinkedin/>
                        </a>
                    </div>
                </div>
            </div>
            
            </div>
            <div className="p-2 bg-slate-100 text-xs md:text-sm text-center md:text-left">
            <p>© 2024 Osama Elshaheer. All rights reserved.</p>
            </div>
        </>
    )
}