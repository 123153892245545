

import 'aos/dist/aos.css';


import image from '.././img/upark-logo.png'
export default function Aside () {

    
    return (
        <div className='w-full bg-font_image flex justify-center'>
            <img data-aos="zoom-in" data-aos-anchor-placement="top-bottom" className='w-80' src={image} alt="" />
        </div>
    )
}