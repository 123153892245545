
import Title from './Title' ;
import Muruj from '.././carousel/Muruj' ;
import Upark from '.././carousel/Upark'
import '../css/Container.css' ;
import 'aos/dist/aos.css';
export default function Container () {
    return (
        <>
        <div className="bg-white p-5 lg:px-28">
            <div className='flex justify-center text-2xl  md:text-3xl font-extrabold shadow-2xl mb-4 text-font_blue italic'>
            <p data-aos="zoom-in" data-aos-anchor-placement="top-bottom">UPARK</p>
            </div>
            <div data-aos="slide-left" data-aos-anchor-placement="top-bottom" className='flex flex-col'>
            <span className='mb-8 mt-2 text-font_blue text-sm lg:text-lg'><strong>Tasheed</strong> Real Estate Development Company is one of the developers of the Morouj Smouha project, which is a project within the initiative of the President of the Arab Republic of Egypt <strong>Together we build the future</strong> under the supervision of the General Authority of the Armed Forces to engage the civil society to build new urban communities.</span>
            <span className='text-font_blue text-sm lg:text-lg'>The project consists of 182 acres dedicated to construction, only 22% of the project area and the rest of the area is allocated to different services</span>
            <ul className='custom-marker text-xs md:text-sm'>
                <li>
                School on an area of 20000 meters.
                </li>
                <li>
                International hospital on an area of 15000 m.
                </li>
                <li>
                Club on an area of 13.6 acres (57000 m.).
                </li>
                <li>
                Hotel.
                </li>
                <li>
                Commercial mall.
                </li>
            </ul>
            <span className='mt-5 text-font_blue text-sm lg:text-lg'>Location in front of the Green Plaza and behind the building of the new governorate and next to the Aletihad Club and close to the Sporting Club,The Pharos University and the new branch of Alexandria University and its close to all main roads (Agricultural Road – Mahmudiyah Axis – Desert Road – North Coast),The company has two distinct sites in the project.</span>
            </div>
        </div>

        {/* MURUJ */}
        <div className="bg-white p-5 md:px-28 ">
            <Title name="Muruj Icon on Hilton Street"/>
            <div className='flex flex-col mt-3'>
                <span className='text-font_blue text-sm lg:text-lg'>
                Surrounded by a fence of trees and an excellent landscaping , administrative and residential from the fourth floor to the thirteenth floor. 
                </span>
            </div>
            <div className='mt-4'>
                <p className='font-bold text-xl'>Services</p>
                <ul className='custom-marker text-sm lg:text-lg'>
                    <li>
                    Underground garage.
                    </li>
                    <li>
                    Services on the ground.
                    </li>
                    <li>
                    Sport club.
                    </li>
                    <li>
                    Club House.
                    </li>
                    <li>
                    Shops.
                    </li>
                </ul>
            </div>
        </div>
        <Upark/>

        {/* UPARK */}
        <div className="bg-white p-5 md:px-28">
            <Title name="Upark"/>
            <div className='flex flex-col mt-3'>
                <span className='text-font_blue text-sm lg:text-lg'>
                Directly on the club, On an area of 3 acres, completely surrounded by a fence of trees and landscaping and 4 gates equipped with security, guards and 24-hour surveillance cameras.
                </span>
            </div>
            <div className='mt-4'>
                <p className='font-bold text-xl'>Services</p>
                <ul className='custom-marker text-sm lg:text-lg'>
                    <li>
                    Underground garage.
                    </li>
                    <li>
                    Ground floor services.
                    </li>
                    <li>
                    Clubhouse.
                    </li>
                    <li>
                    Kids area.
                    </li>
                    <li>
                    Hotel.
                    </li>
                    <li>
                    Gym.
                    </li>
                </ul>
                <span className='text-sm lg:text-lg'>Park on an area of 1400 meters  with a swimming pool in the middle of it, and places to sit.</span>
            </div>
        </div>
        <Muruj/>


        
        </>
    )
}