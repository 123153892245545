import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import image_1 from '../img/muruj-1.png'
import image_2 from '../img/muruj-2.png'
import image_3 from '../img/muruj-3.png'
import image_4 from '../img/muruj-4.png'

// css
import '../css/Slider.css' ;
import 'aos/dist/aos.css';
const Muruj = () => {

    return (
        <div className="slider-muruj justify-center">
            <div data-aos="zoom-in" data-aos-anchor-placement="top-bottom" className='flex'>
                <img className='w-36 lg:w-60 p-1' src={image_1} alt="" />
                <img className='w-36 lg:w-60 p-1' src={image_2} alt="" />
            </div>
            <div data-aos="zoom-in" data-aos-anchor-placement="top-bottom" className='flex'>
                <img className='w-36 lg:w-60 p-1' src={image_3} alt="" />
                <img className='w-36 lg:w-60 p-1' src={image_4} alt="" />
            </div>
        </div>
    );
};

export default Muruj;