
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

//Images
import image_1 from '../img/upark3-1024x576.jpg' ;
import image_2 from '../img/upark4-1024x576.jpg' ;
import image_3 from '../img/upark5-1024x576.jpg' ;
import image_4 from '../img/upark6-1024x576.jpg' ;
import image_5 from '../img/upark7-1024x576.jpg' ;
import image_6 from '../img/upark8-1024x576.jpg' ;
import image_7 from '../img/upark9-1024x576.jpg' ;
// import image_8 from '../img/upark10-1024x576.jpg' ;

import image_9 from '../img/upark11-1024x576.jpg' ;
import image_10 from '../img/upark12-1024x576.jpg' ;
import image_11 from '../img/upark13-1024x576.jpg' ;
// import image_12 from '../img/upark14-1024x576.jpg' ;
import image_13 from '../img/upark15-1024x576.jpg' ;
import image_14 from '../img/upark16-1024x576.jpg' ;
import image_15 from '../img/upark17-1024x576.jpg' ;
import image_16 from '../img/upark18-1024x576.jpg' ;

import image_17 from '../img/upark19-1024x576.jpg' ;
import image_18 from '../img/upark20-1024x576.jpg' ;
import image_19 from '../img/upark21-1024x576.jpg' ;
import image_20 from '../img/upark22-1024x576.jpg' ;
import image_21 from '../img/upark23-1024x576.jpg' ;
import image_22 from '../img/upark24-1024x576.jpg' ;
import image_23 from '../img/upark25-1024x576.jpg' ;
import image_24 from '../img/upark26-1024x576.jpg' ;

import image_25 from '../img/upark27-1024x576.jpg' ;
import image_26 from '../img/upark28-1024x576.jpg' ;
import image_27 from '../img/upark29-1024x576.jpg' ;
import image_28 from '../img/upark32-1024x576.jpg' ;
import image_29 from '../img/upark33-1024x576.jpg' ;
import image_30 from '../img/upark34-1024x576.jpg' ;
import image_31 from '../img/upark36-1024x576.jpg' ;
import image_32 from '../img/upark37-1024x576.jpg' ;
// css
import '../css/Slider.css'
import 'aos/dist/aos'

const ImageSlider = () => {
  const settings = {
    dots: false, // لإظهار النقاط
    infinite: true, // لتمرير الصور بشكل لانهائي
    speed: 1500, // سرعة التمرير (0.9 ثانية)
    slidesToShow: 1, // عرض شريحة واحدة في كل مرة
    slidesToScroll: 1, // التمرير شريحة واحدة في كل مرة
    autoplay: true, // لتفعيل التمرير التلقائي
    autoplaySpeed: 100, // سرعة التمرير التلقائي (1 ثانية)
    cssEase: 'linear' // لجعل التمرير سلساً
  };
  
    const images = [
      image_1,
      image_2,
      image_3,
      image_4,
      image_5,
      image_6,
      image_7,
      image_9,
      image_10,
      image_11,
      image_13,
      image_14,
      image_15,
      image_16,
      image_17,
      image_18,
      image_19,
      image_20,
      image_21,
      image_22,
      image_23,
      image_24,
      image_25,
      image_26,
      image_27,
      image_28,
      image_29,
      image_30,
      image_31,
      image_32,
    ];
  
    return (
      <div data-aos="zoom-in" data-aos-anchor-placement="top-bottom" className="slider-container rounded-lg overflow-hidden">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`slide-${index}`} />
            </div>
          ))}
        </Slider>
      </div>
    );
  };

  export default ImageSlider;