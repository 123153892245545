import { useState } from 'react';
import { Link } from 'react-router-dom';
import { BsList, BsXCircle } from 'react-icons/bs';
import Logo from './components/Logo';

export default function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
        
        <div>
            <div className="z-50 relative bg-gray-50 px-2 lg:px-28 py-1 flex justify-between items-center border-b-2 border-orange-200">
                <Logo />
                {/* discTop */}
                <div className='pe-5 hidden md:flex justify-between gap-5 font-bold text-orange-400'>
                    <Link to="/">HOME</Link>
                    <Link to="/our-work">WHAT WE DO</Link>
                    <Link to="/property">PROPERTY</Link>
                    <Link to="/contact">CONTACT US</Link>
                </div>
                {/* mobile */}
                <div className=' block md:hidden pe-4 text-2xl text-orange-700 cursor-pointer' onClick={toggleMenu}>
                    <p className='font-bold'>{isMenuOpen ? <BsXCircle/> : <BsList/>}</p>
                </div>
            </div>
            
            </div>
            {/* Overlay */}
            {isMenuOpen && (
            <div
            className={`h-screen z-10 text-sm absolute top-24  w-full bg-black opacity-85 py-5 px-4 flex flex-col items-center gap-5 font-bold text-red-500 transition-transform duration-300 ease-in-out ${isMenuOpen ? 'transform translate-y-0' : 'transform -translate-y-full'}`}
            onClick={toggleMenu}
            >
                <Link to="/">HOME</Link>
                <Link to="/our-work">WHAT WE DO</Link>
                <Link to="/property">PROPERTY</Link>
                <Link to="/contact">CONTACT US</Link>
            </div>
            )}
        </>
    );
}
