import Aside from '../components/Aside';
import Cards from '../components/Cards';
import Container from '../components/Container'
import Gallery from '../components/Gallery';
import ImageSlider from '../components/ImageSlider';
import imageUrl from '../img/partners.png';
import Main from '../components/Main' ;
const containerStyle = {
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover', 
    backgroundPosition: 'center', 
    background: '5px solid rgba(255, 255, 255, 0.5)', 
};

export default function Home() {
    return (
    <>
    <Main/>
    
    <div style={containerStyle}>
        <Cards />
    </div>
    <div style={containerStyle}>
    <Container/>
    </div>
    <div style={containerStyle}>
    <Aside/>
    </div>
    
    
    <div>
    <ImageSlider/>
    <Gallery/>
    </div>
    </>
    );
}

