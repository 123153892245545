import { FaPhone } from "react-icons/fa6";
import { FaRegEnvelope } from "react-icons/fa6";
import 'aos/dist/aos'
export default function TopNav(){
    return (
        <header className="z-30 bg-gray-200 flex justify-evenly md:px-28 text-xs lg:text-sm py-2 md:font-bold">
            <div data-aos="zoom-in" className="bg-gray-200 flex justify-center items-center ">
                <small className="text-customRed"><FaPhone/></small>
                <a href="tel:16673" className="text-customRed">16673</a>
            </div>
            <div data-aos="zoom-in" className="bg-gray-200 flex justify-center items-center ">
                <small className="text-customRed p-1 pt-2"><FaRegEnvelope/></small>
                <span className="text-customRed">tasheed@tasheedegypt.com</span>
            </div>
        </header>
    )
}


